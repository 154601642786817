<template>
  <header class="authenticated-navbar" data-testid="authenticated-navbar">
    <div class="authenticated-navbar-panel">
      <!--	  logo svg -->
      <DsLink class="authenticated-navbar-logo" :to="{ name: 'Dashboard' }">
        <JoinBrandsLogoIcon class="w-[33px] h-11" />
      </DsLink>
      <!--	 Links navbar lg to 2xl -->
      <div
        v-if="$grid('hidden xl:block')"
        class="authenticated-navbar-panel-access"
      >
        <NavbarItem
          :to="{ name: 'Dashboard' }"
          route-name="Dashboard"
          :text="$t('common.route.dashboard.link')"
          class="mr-8"
        />
        <NavbarItem
          :to="{ name: 'Marketplace' }"
          route-name="Marketplace"
          :text="$t('common.route.marketplace.link')"
          class="mr-8"
        />
        <NavbarItem
          :to="{ name: 'Jobs' }"
          route-name="Jobs"
          :text="$t('common.route.jobs.link')"
          class="mr-8"
        />
        <NavbarItem
          :to="{ name: 'TheRecurringJobs' }"
          route-name="TheRecurringJobs"
          :text="$t('common.route.recurringJobs.link')"
          class="mr-8"
        />
      </div>
      <!--	  Icons, Avatar and button sidebar-->
      <div class="ml-auto flex items-center gap-2 lg:gap-0">
        <template v-if="isImpersonated">
          <DsBadge
            :title="$t('common.label.admin', { id: adminId })"
            size="xs"
            class="mr-3"
            data-testid="admin-impersonated-badge"
          />
          <DsBadge
            v-if="isSuspended"
            :title="$t('common.label.suspended')"
            theme-color="red"
            size="xs"
            class="mr-3"
            data-testid="user-suspended-badge"
          />
        </template>
        <a
          href="https://www.producthunt.com/posts/joinbrands?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-joinbrands"
          target="_blank"
          class="mr-4 inline-block"
          ><img
            src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=523360&theme=light"
            alt="JoinBrands - Connect&#0032;brands&#0032;with&#0032;TikTok&#0032;affiliates&#0032;and&#0032;UGC&#0032;creators | Product Hunt"
            style="width: 200px; height: 42px"
            width="200"
            height="42"
        /></a>
        <NavbarItem
          :to="{ name: 'Wallet' }"
          route-name="Wallet"
          :text="wallet"
          class="mr-6 hidden sm:block"
        />
        <NavbarItem
          route-name="TheChat"
          :to="{
            name: 'TheChat',
            params: { chatId: $route.params.chatId },
          }"
          icon="DsChatBubbleIcon"
          class="chat-icon"
          data-testid="chat-icon"
        >
          <template #navbar-item>
            <DsDotSolidIcon
              v-if="hasNewChats"
              class="chat-dot-icon"
              data-testid="new-chats-dot-icon"
            />
          </template>
        </NavbarItem>
        <NavbarItem
          route-name="Notifications"
          icon="DsBellSolidIcon"
          class="notification-icon"
          :link="false"
          data-testid="app-notifications-icon"
          @click="onClickShowNotifications"
        >
          <template #navbar-item>
            <DsDotSolidIcon
              v-if="hasNotifications"
              class="notification-dot-icon"
              data-testid="app-notifications-dot-icon"
            />
          </template>
        </NavbarItem>
        <div v-if="$grid('xl:hidden')" class="flex items-center">
          <button @click="showMenu = !showMenu">
            <DsMenuSolidIcon class="w-6 h-6 text-jb-gray-900" />
          </button>
        </div>
        <div v-if="$grid('hidden xl:block')">
          <DsDropdown :items="avatarLinks" close-on-mouse-leave>
            <template #button>
              <DsAvatar
                :alt="fullName"
                :src="profileImage"
                size="sm"
                data-testid="avatar-dropdown-menu"
              />
            </template>
          </DsDropdown>
        </div>
      </div>
    </div>
  </header>
</template>

<script lang="ts" setup>
import { computed } from "vue";

import { useChatStore } from "@/store/ChatStore";

import type { DsDropdownItem } from "@devsalsa/vue-core";
import {
  DsAvatar,
  DsBadge,
  DsDotSolidIcon,
  DsDropdown,
  DsLink,
  DsMenuSolidIcon,
  DsModalHandler,
  useDsCurrency,
  useDsLiveNotifierStore,
} from "@devsalsa/vue-core";

import type { AccountInfo } from "@/core/modules/account/services/AccountService.types";
import NavbarItem from "@/core/shared/components/Navigation/NavbarItem.vue";
import AccountHandler from "@/core/shared/helpers/Account/AccountHandler";

import { useI18n } from "vue-i18n";

import JoinBrandsLogoIcon from "@/shared/components/icon/JoinBrandsLogoIcon.vue";

defineOptions({
  name: "AuthenticatedNavbar",
});

const emit = defineEmits(["update:offCanvasValue", "update:notificationValue"]);

const props = defineProps({
  offCanvasValue: {
    required: true,
    type: Boolean,
  },
  notificationValue: {
    required: true,
    type: Boolean,
  },
});

const { t } = useI18n();
const { format } = useDsCurrency();

const avatarLinks = [
  {
    label: t("core.shared.components.layout.navbar.account"),
    route: {
      name: "Account",
    },
  },
  {
    label: t("core.shared.components.layout.navbar.portfolio"),
    route: {
      name: "PortfolioSummary",
    },
  },
  {
    label: t("core.shared.components.layout.navbar.reviews"),
    route: {
      name: "Reviews",
    },
  },
  {
    label: t("core.shared.components.layout.navbar.wallet"),
    route: {
      name: "Wallet",
    },
  },
  {
    label: t("core.shared.components.layout.navbar.payoutMethod"),
    route: {
      name: "PayoutMethod",
    },
  },
  {
    label: t("core.shared.components.layout.navbar.certifications"),
    route: {
      name: "Certifications",
    },
  },
  {
    label: t("core.shared.components.layout.navbar.notifications"),
    route: {
      name: "NotificationSettings",
    },
  },
  {
    label: t("core.shared.components.layout.navbar.requestFeature"),
    route: {
      name: "RequestAFeature",
    },
  },
  {
    label: t("core.shared.components.layout.navbar.contact"),
    action: () => onOpenModal(),
    dataTestid: "contact-support-avatar-menu-link",
  },
  {
    label: t("core.shared.components.layout.navbar.affiliate"),
    route: {
      name: "AffiliateProgram",
    },
  },
  {
    label: t("common.route.logout.link"),
    route: {
      name: "Logout",
    },
    cssClasses: "border-t border-gray-100",
  },
] as DsDropdownItem[];

const showMenu = computed({
  get(): boolean {
    return props.offCanvasValue || false;
  },
  set(val: boolean) {
    emit("update:offCanvasValue", val);
  },
});

const showNotifications = computed({
  get(): boolean {
    return props.notificationValue || false;
  },
  set(notificationVal: boolean) {
    emit("update:notificationValue", notificationVal);
  },
});

const accountInfo = computed((): AccountInfo => {
  return AccountHandler.accountInfo.value as AccountInfo;
});

const fullName = computed((): string => {
  return `${accountInfo.value?.first_name ?? ""} ${
    accountInfo.value?.last_name ?? ""
  }`;
});

const profileImage = computed((): string => {
  return `${accountInfo.value?.profile_image || ""}`;
});

const wallet = computed((): string => {
  return format(
    Number(accountInfo.value?.wallet_earnings_balance) +
      Number(accountInfo.value?.wallet_reimbursements_balance)
  );
});

const hasNotifications = computed((): boolean => {
  return useDsLiveNotifierStore().unread > 0;
});

const hasNewChats = computed((): boolean => {
  return useChatStore().unreadChats > 0;
});

const isSuspended = computed((): boolean => {
  return accountInfo.value.suspended;
});

const adminId = computed((): number => {
  return accountInfo.value?.admin_id ?? 0;
});

const isImpersonated = computed((): boolean => {
  return adminId.value > 0;
});

function onClickShowNotifications() {
  showNotifications.value = true;
}

function onOpenModal() {
  DsModalHandler.open("contact-support-modal");
}
</script>

<style lang="scss" scoped>
.authenticated-navbar {
  @apply w-full h-fit bg-white border-b border-gray-200;

  &-panel {
    @apply xl:container px-3 md:px-4 xl:px-6 flex xl:mx-auto;

    &-access {
      @apply flex items-center justify-center mx-8;
    }
  }

  &-logo {
    @apply flex items-center flex-none w-[33px] h-16;
  }
}

.notification-icon {
  @apply relative w-8 h-8 sm:mr-4 mr-1 flex justify-center items-center;

  :deep(svg:not(.notification-dot-icon)) {
    @apply w-6 h-6;
  }
}

.chat-icon {
  @apply relative w-8 h-8 sm:mr-3 flex justify-center items-center;

  :deep(svg:not(.chat-dot-icon)) {
    @apply w-6 h-6;
  }
}

.notification-dot-icon {
  @apply w-3 h-3 absolute right-0 top-0 text-jb-pink-500 border-2 border-white rounded-full;
}

.chat-dot-icon {
  @apply w-3 h-3 absolute right-0 top-0 text-jb-pink-500 border-2 border-white rounded-full;
}
</style>
